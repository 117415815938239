import React, { Suspense, lazy } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from './history';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Spinner from 'components/common/Spinner';
import Axios from 'utils/Axios';
import { logOut } from 'reduxStore/appStatic/actions';


const HomePage = lazy(() => import('containers/Home'));
const MealPlansPage = lazy(() => import('containers/MealPlans'));
const MealPlanPage = lazy(() => import('containers/MealPlan'));
const HowItWorksPage = lazy(() => import('containers/HowItWorks'));
const FaqPage = lazy(() => import('containers/Faq'));
const WeeklyMeals = lazy(() => import('containers/WeeklyMeals'));
const Error = lazy(() => import('containers/Error'));
const OrderPlan = lazy(() => import('containers/OrderPlan'));
const NewsPage = lazy(() => import('containers/NewsPage'));
const NewsDetailPage = lazy(() => import('containers/NewsDetailPage'));
const AboutUsPage = lazy(() => import('containers/AboutUsPage'));
const LoginPage = lazy(() => import('containers/Auth/Login'));
const ForgotPage = lazy(() => import('containers/Auth/ForgotPassword'));
const SetPassword = lazy(() => import('containers/Auth/SetPassword'));
const OrderMeal = lazy(() => import('containers/OrderMeal'));
const Contacts = lazy(() => import('containers/Contacts'));
const GiftCard = lazy(() => import('containers/GiftCard'));
const UnderConstruction = lazy(() => import('containers/UnderConstruction'));
const Profile = lazy(() => import('containers/Profile'));


const redirectIfLoggedIn = ['/login', '/forgot-password', '/password'];


const RouteConfig = ({ user, component: Component, ...rest }) => {
  if (redirectIfLoggedIn.includes(rest.path) && !isEmpty(user)) {
    const link = window.location.origin;
    window.location.replace(`${link}/profile/`);
    return null;
  }
  return (
    <Route
      {...rest}
      render={props =>
        <Suspense fallback={<Spinner/>}>
          <Component {...props} />
        </Suspense>
      }
    />
  );
};
const mapStateToProps = state => ({
  user: state.appStatic.user,
});
const AppRoute = connect(mapStateToProps)(RouteConfig)


class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    if (!isEmpty(props.user)) {
      Axios.get('/user?include=addresses')
        .then(() => {
        })
        .catch(() => this.props.logOut())
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <AppRoute exact path="/" component={HomePage} />
          <AppRoute exact path="/meal-plans" component={MealPlansPage} />
          <AppRoute path="/meal-plan/:id(\d+)" component={MealPlanPage} />
          <AppRoute exact path="/how-it-works" component={UnderConstruction} />
          <AppRoute exact path="/weekly-meals" component={UnderConstruction} />
          <AppRoute exact path="/order-plan" component={OrderPlan} />
          <AppRoute exact path="/faq" component={UnderConstruction} />
          <AppRoute exact path="/news" component={UnderConstruction} />
          <AppRoute exact path="/news-detail" component={NewsDetailPage} />
          <AppRoute exact path="/about" component={UnderConstruction} />
          <AppRoute exact path="/login" component={LoginPage} />
          <AppRoute exact path="/forgot-password" component={ForgotPage} />
          <AppRoute exact path="/password" component={SetPassword} />
          <AppRoute exact path="/order-meal" component={OrderMeal} />
          <AppRoute exact path="/contact-us" component={Contacts} />
          <AppRoute exact path="/gift-card" component={GiftCard} />
          <AppRoute exact path="/privacy-policy" component={UnderConstruction} />
          <AppRoute exact path="/profile/:page?" component={Profile} />
          <AppRoute component={Error} />
        </Switch>
      </Router>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
